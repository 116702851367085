import ObsClient from "./obs/esdk-obs-browserjs.3.22.3.min.js";
// const ak = '9V5DYF5Y2KVIKCODXCHJ';
// const sk = 'jwiuAaQBRetr6kglPcfhPklrznBIW8j0qCNeRRhP';
// const server = 'obs.cn-north-4.myhuaweicloud.com';
const ak = 'JFQQK2T6H7CEU2KHDFFF';
const sk = 'uczKVe0F1uREAnggbFpRVpypzjdnIDKXeG4jYRFd';
const server = 'obs.cn-east-3.myhuaweicloud.com';
import {
    Message,
} from 'element-ui'
const isTest = (location.href.indexOf('192.168') != -1 || location.href.indexOf('.test.') != -1) ? 'test/' : ''


const upload = (file, key, bucketName) => {
    const obsClient = new ObsClient({
        access_key_id: ak,
        secret_access_key: sk,
        server: server,
        timeout: 60 * 5,
    });
    return new Promise((resolve, reject) => {
        obsClient.getObjectMetadata({
            Bucket: bucketName,
            Key: key || file.name,
        }).then(function (result) {
            if (result.CommonMsg.Status < 300) {
                console.log('Get object metadata，已经有同名文件');
                reject('have');
                Message("该文件已经上传过了");
            } else {
                console.log('没有文件，就上传')
                obsClient.putObject(
                    {
                        Bucket: bucketName,
                        Key: key || file.name,
                        SourceFile: file,
                    },
                    function (err, result) {
                        if (err) {
                            reject('err')
                            console.log(err);
                            Message("上传失败");
                        } else {
                            console.log(result);
                            if (result.CommonMsg.Status < 300) {
                                console.log('Create object:' + key + ' successfully!\n');
                                resolve('success')
                            } else {
                                console.log('Code-->', result.CommonMsg.Code);
                                console.log('Message-->', result.CommonMsg.Message);
                                reject('err')
                                Message("上传失败");
                            }
                        }
                    }
                );
            }
        });
    })
}

const del = (file, key, id) => {
    const obsClient = new ObsClient({
        access_key_id: ak,
        secret_access_key: sk,
        server: server,
        timeout: 60 * 5,
    });
    let bucketName = `servicetuoyusaas/${isTest}id_${id}`;
    return new Promise((resolve, reject) => {
        obsClient.deleteObject({
            Bucket: bucketName,
            Key: key || file.name,
        }).then(function (result) {
            if (result.CommonMsg.Status < 300) {
                console.log('Delete object finished.\n');
                resolve('success')
            } else {
                Message("删除失败");
            }
        });
    })
}

export default {
    upload,
    del
}